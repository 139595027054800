// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./src/jquery" // if jQuery is used in stimulus controllers add before line 3
import {} from 'jquery-ujs'
import "jquery-ui"
import "./parsley.js"

import "./leaflet_1_5_1.js"
import "./leaflet_draw_1_0_4.js"
